<template>
  <div class="mdc-dialog"
       role="alertdialog"
       aria-modal="true"
       aria-labelledby="my-dialog-title"
       aria-describedby="my-dialog-content"
       ref="dialog"
       :aria-hidden="!dialogOpened"
       :class="{borderless: borderless}"
      >
    <div class="mdc-dialog__container">
      <div class="mdc-dialog__surface">
        <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
        <h2 class="mdc-dialog__title" id="my-dialog-title" v-if="title" v-html="title"></h2>

        <div class="mdc-dialog__content" id="my-dialog-content" :tabindex="dialogOpened ? 0 : -1">
          <slot></slot>
        </div>

        <footer class="mdc-dialog__actions" v-if="showActions">
          <slot name="actions"></slot>
        </footer>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script>
import MdcDialogMixin from './MdcDialogMixin'

export default {
  name: 'MdcDialog',
  mixins: [
    MdcDialogMixin
  ],
  props: {
    borderless: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@material/dialog/mdc-dialog";

.mdc-dialog {
  border-radius: 0;

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
    cursor: pointer;
    color: #ccc;
    z-index: 999;
  }
  
  .mdc-dialog__content:focus {
    outline: none;
  }
}.borderless {
  .mdc-dialog__content {
    margin: 0;
    padding: 0;
  }
}
</style>
