<template>
  <div
    ref="userCard"
    class="user-card"
    :class="{narrow: narrow, dark: dark}"
    :style="componentStyle">

    <LoginRequiredMask
      class="login-required-mask"
      v-if="!hideLoginRequiredMask"
      :login-url="loginUrl"
      :login-text="loginText"
      :already-logged-in="userProfile !== null">
    </LoginRequiredMask>

    <LoadingBox
      v-if="loading"
      class="loading-box">
    </LoadingBox>

    <div class="roundPicture" :style="pictureStyle">
      <i class="material-icons" v-show="!userProfilePicture">
      face
      </i>
    </div>
    <div class="info">
      <h2 class="username"
        v-if="!hideUsername">
        {{userProfileUsername}}
      </h2>
      <h3 class="email"
        v-if="!hideEmail">
        {{userProfileEmail}}
      </h3>
    </div>
  </div>
</template>

<script>
import LoadingElementMixin from '@paiuolo/pai-vue-mixins/mixins/LoadingElementMixin'
import FetchUserProfileMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/FetchUserProfileMixin'
import UserProfileMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/UserProfileMixin'

import LoginRequiredMask from '../user/LoginRequiredMask'
import LoadingBox from '../../elements/loader/LoadingBox'

export default {
  name: 'UserCard',
  mixins: [
    LoadingElementMixin,
    FetchUserProfileMixin,
    UserProfileMixin
  ],
  components: {
    LoginRequiredMask,
    LoadingBox
  },
  props: {
    /**
     * Login redirect url
     */
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.org/login/'
    },
    userProfileUrl: {
      type: String,
      default: 'https://accounts.q-cumber.org/api/v1/auth/profile/'
    },
    loginText: {
      type: String,
      default: 'Login'
    },
    narrow: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    hideEmail: {
      type: Boolean,
      default: false
    },
    hideUsername: {
      type: Boolean,
      default: false
    },
    hideLoginRequiredMask: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.$on('performingFetchUserProfile', () => {
      this.startLoading()
    })
    this.$on('errorFetchingUserProfile', () => {
      this.stopLoading()
    })
    this.$on('userProfileFetched', () => {
      this.stopLoading()
    })

    if (this.auto) {
      this.fetchUserProfile()
    }
  },
  computed: {
    componentStyle () {
      let style = {}

      if (this.backgroundColor) {
        style['background-color'] = this.backgroundColor
      }
      if (this.color) {
        style['color'] = this.color
      }
      return style
    },
    pictureStyle () {
      let style = {}

      if (this.userProfilePicture) {
        style['background-image'] = 'url(\'' + this.userProfilePicture + '\')'
      }
      return style
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/theme";
@import "../../../scss/flexbox";

$break-phone: 480px;
$break-tablet: 840px;

@import "@material/textfield/mdc-text-field";
@import "@material/textfield/icon/mdc-text-field-icon";
@import "@material/elevation/mdc-elevation";

.user-card {
  position: relative;
  background-color: #fff;

  @include flexbox();
  @include flex-direction(column);

  align-items: center;
  padding: 24px;
  text-align: center;
  color: #222;

  .info {
    @include flex(1);
  }

  .roundPicture {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background-color: #fff;
    padding: 12px;

    .material-icons {
      font-size: 100px;
    }
  }

  @media screen and (min-width: $break-tablet) {
    @include flex-direction(row);

    align-items: center;
    text-align: left;
    justify-content: center;

    .info {
      margin-left: 32px;
    }
  }
}
.user-card:hover {
  /*@include mdc-elevation(1);*/
}
.user-card.narrow {
  @include flexbox();
  @include flex-direction(column);

  align-items: center;
  padding: 24px;
  text-align: center;

  .info {
    margin-left: 0;
  }
}
.user-card.dark {
  color: #fff;
}
</style>
