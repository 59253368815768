<template>
  <label ref="inputContainer"
    class="mdc-text-field mdc-text-field--filled mdc-text-field--with-trailing-icon mdc-text-field--fullwidth mdcTextField"
    :class="{big: big, dark: dark}"
    >

    <span class="mdc-text-field__ripple"></span>

    <input ref="input"
      class="mdc-text-field__input"
      :type="type"
      :aria-label="label"
      @input='evt=>value=evt.target.value'
      :placeholder="placeholder"
      :name="name"
      :required="required"
      :minlength="minlength"
      :tabindex="tabindex"
      @keyup.enter="$emit('submitted')"
      v-model="value">

    <i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing" role="button"
      ref="icon"
      v-html="icon"
      @click="clear"
      :tabindex="nextTabindex(tabindex)"></i>

    <span class="mdc-floating-label" id="my-label-id">{{label}}</span>
    <span class="mdc-line-ripple"></span>
  </label>
</template>

<script>
import MdcTextFieldMixin from '../MdcTextFieldMixin'

export default {
  name: 'LoyaltyMdcTextField',
  mixins: [
    MdcTextFieldMixin
  ],
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

@import "@material/textfield/mdc-text-field";
@import "@material/textfield/icon/mdc-text-field-icon";

.mdc-text-field {
  @include mdc-text-field-trailing-icon-color(#111);

  @include mdc-text-field-ink-color(#111);
  @include mdc-text-field-label-color($mdc-theme-secondary);
  @include mdc-text-field-caret-color($selected);

  @include mdc-text-field-bottom-line-color($mdc-theme-secondary);
  @include mdc-text-field-hover-bottom-line-color($mdc-theme-secondary);

  @include mdc-text-field-line-ripple-color($selected);
}
.mdc-text-field.mdc-text-field--focused {
  @include mdc-text-field-trailing-icon-color($selected);
}

.mdc-text-field.mdc-text-field--invalid {
  @include mdc-text-field-trailing-icon-color(#f00);
  @include mdc-text-field-bottom-line-color(#f00);
  @include mdc-text-field-ink-color(#f00);
}

.dark.mdc-text-field {
  @include mdc-text-field-trailing-icon-color(#fff);
  @include mdc-text-field-ink-color(#fff);
  @include mdc-text-field-label-color(#fff);

  @include mdc-text-field-bottom-line-color(#fff);
  @include mdc-text-field-hover-bottom-line-color(#fff);
}
.dark.mdc-text-field.mdc-text-field--focused {
  @include mdc-text-field-trailing-icon-color($selected);
}

.big.mdc-text-field {
  .mdc-text-field__input {
    font-size: 2em;
  }
}
</style>
