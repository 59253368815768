<template>
<div class="subscribe-loyalty-campaign-card">
  <div class="intestazione">
    <div class="logo" :style="logoStyle"></div>
    <div class="titolo">
      <div class="item-slogan" v-if="entity.slogan" v-html="entity.slogan"></div>
      <div class="item-slogan" v-else>
        <img v-if="entity.image" :src="entity.image" :alt="entity.name" />
        <h4 v-else>{{entity.name}}</h4>
      </div>
    </div>
  </div>
  <div class="corpo">
      <slot></slot>
  </div>
</div>
</template>

<script>
import EntityAsPropMixin from '@paiuolo/pai-vue-mixins/mixins/EntityAsPropMixin'
import EntityWithLogoMixin from '@paiuolo/pai-vue-mixins/mixins/EntityWithLogoMixin'

export default {
  name: 'SubscribeCampaignCard',
  mixins: [
    EntityAsPropMixin,
    EntityWithLogoMixin
  ]
}
</script>

<style lang="scss">
@import "../../../../../scss/loyalty.theme";

@import "@material/card/mdc-card";

.subscribe-loyalty-campaign-card {
  .intestazione {
    @include flexbox();
    @include flex-direction(row);

    .logo {
      width: 140px;
      height: 140px;
      margin-top: 36px;
      /*margin-left: 36px;*/
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: auto;
      background-position-x: center;
      background-position-y: center;

      @media screen and (max-width: $break-tablet) {
        width: 100px;
        height: 100px;
      }
      @media screen and (max-width: $break-phone) {
        display: none;
      }
    }

    .titolo {
      @include flex(1);
      @include flexbox();
      @include flex-direction(row);

      align-items: center;
      justify-content: center;
      margin: 36px 36px 16px;
      
      .item-slogan {
        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 8px auto;
        }
      }
      
    }
  }

  .corpo {
    box-sizing: border-box;
    margin: 0 32px;
    height: 260px;
    overflow: hidden;

    .scrollable {
      height: 100%;
      overflow-y: auto;
      /*margin-right: -16px;*/
    }
  }
}
</style>
