import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import VueCustomElement from 'vue-custom-element'

import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

import LoyaltyCampaignList from './components/entities/loyalty/campaign/LoyaltyCampaignList'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueCustomElement)

Vue.use(VueGettext, {
  callback: function (lang) {
    moment.locale(lang)
  }
})

Vue.customElement('loyalty-campaign-list', LoyaltyCampaignList)

