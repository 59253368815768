// import {MDCDialog} from '@material/dialog/dist/mdc.dialog'  //built
import {MDCDialog} from '@material/dialog'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogOpened: false
    }
  },
  mounted () {
    let el = this.getElement()

    this.dialog = new MDCDialog(el)

    this.dialog.listen('MDCDialog:opened', (ev) => {
      this.opened(ev)
    })
    this.dialog.listen('MDCDialog:closing', (ev) => {
      this.closed(ev)
    })

    this.$nextTick(() => {
      if (this.show) {
        this.open()
      }
    })
  },
  methods: {
    getElement () {
      return this.$refs.dialog
    },
    open (ev) {
      if (!this.dialogOpened) {
        this.dialogOpened = true
        // console.log('open dialog')
        if (ev) {
          this.dialog.lastFocusedTarget = ev.target
        }
        this.dialog.open()
      }
    },
    close () {
      if (this.dialogOpened) {
        this.dialogOpened = false
        // console.log('close dialog')
        this.dialog.close()
      }
    },
    closed (ev) {
      this.dialogOpened = false
      this.$emit('closed', ev)
    },
    opened (ev) {
      this.dialogOpened = true
      this.$emit('opened', ev)
    }
  },
  watch: {
    show (n) {
      if (n) {
        this.open()
      } else {
        this.close()
      }
    }
  }
}
