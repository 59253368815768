<template>
  <swiper-js
    class="subscribe-loyalty-campaign"
    ref="swiper"
    :swipeable="false"
    :allowSlideNext="false"
    :allowSlidePrev="false">

<!-- check_user -->
      <div class="swiper-slide">
        <div class="step">

          <SubscribeLoyaltyCampaignCard
            :entity="campaign"
          >

          <div>
            <UserCard
              ref="userCard"
              :external-user-profile="subscriber"
              :login-url="loginUrl"
              narrow
              no-over />

            <div v-if="campaign.subscribed"
              class="saldo_punti">
              <h2 v-if="campaign.score_assignments.length > 0">SALDO PUNTI</h2>
              <div v-for="item in campaign.score_assignments" :key="item.id">
                <h1>{{getYearAndMonth(item.assignment_date)}}: <span class="score_assignment_score">{{item.score}}</span></h1>
              </div>
            </div>
          </div>

          </SubscribeLoyaltyCampaignCard>

          <div class="actions">
            <div class="separator"></div>
            <LoyaltyMdcButton
              v-if="subscriber === null"
              big
              @click="performLogin"
              >
              {{gettext('Login')}}
            </LoyaltyMdcButton>
            <LoyaltyMdcButton
              v-else
              big
              @click="activateStep('read_info')"
              >
              <span v-if="campaign.subscribed">
                Info
              </span>
              <span v-else>
                {{continueString}}
              </span>
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- read_info -->
      <div class="swiper-slide">
        <div class="step">

          <SubscribeLoyaltyCampaignCard
            :entity="campaign"
          >
            <div class="scrollable" v-html="campaign.tos"></div>
          </SubscribeLoyaltyCampaignCard>

          <div class="actions">
            <LoyaltyMdcButton
              @click="activateStep('check_user')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator"></div>
            <LoyaltyMdcButton v-if="!campaign.subscribed"
              big
              @click="activateStep('accept_privacy')"
              >
              Ok
            </LoyaltyMdcButton>
            <LoyaltyMdcButton v-else
              big
              @click="operationCancelled"
              >
              Ok
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- accept_privacy -->
      <div class="swiper-slide">
        <div class="step">

          <SubscribeLoyaltyCampaignCard
            :entity="campaign" >
            <div class="tos" v-html="tos2"></div>
          </SubscribeLoyaltyCampaignCard>

          <div class="actions">
            <LoyaltyMdcButton
              @click="activateStep('read_info')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator"></div>
            <LoyaltyMdcButton v-if="!campaign.subscribed"
              big
              @click="activateStep('insert_id_tari')"
              >
              {{acceptString}}
            </LoyaltyMdcButton>
            <LoyaltyMdcButton v-else
              big
              @click="operationCancelled"
              >
              Ok
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- insert_id_tari -->
      <div class="swiper-slide">
        <div class="step">
          <SubscribeLoyaltyCampaignCard
            :entity="campaign"
          >
            <div class="external_id__input">
              <LoyaltyMdcTextField
                ref="externalIdInput"
                class="externalIdInput"
                big
                :placeholder="externalIdFieldName"
                :helptext="getStepDescription('insert_id_tari')"
                @input="updateExternalId"
                @submitted="gotExternalId"
              >
              </LoyaltyMdcTextField>
              <p v-html="getStepDescription('insert_id_tari')">
              </p>
              <img src="/static/loyalty/img/INFO_ID_TARI.jpg" alt="ID TARI" />
            </div>
          </SubscribeLoyaltyCampaignCard>
          <div class="actions">
            <LoyaltyMdcButton
              @click="activateStep('read_info')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator">&nbsp;</div>
            <LoyaltyMdcButton
              v-if="externalId !== null"
              big
              @click="gotExternalId"
              >
              {{continueString}}
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- subscribe -->
      <div class="swiper-slide">
        <div class="step">

          <SubscribeLoyaltyCampaignCard
            :entity="campaign"
          >
            <h3>
              {{getStepDescription('subscribe')}}
            </h3>
          </SubscribeLoyaltyCampaignCard>

          <div class="actions">
            <div class="separator"></div>
            <LoyaltyMdcButton
              big
              @click="operationCancelled"
              >
              OK
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- review_subscriprion -->
      <div class="swiper-slide">
        <div class="step">
          <SubscribeLoyaltyCampaignCard
            :entity="campaign"
          >
            <div v-if="campaignSubscriptionError"
              v-html="campaignSubscriptionError">
            </div>
            <div v-else
              v-html="campaignSubscriptionMessage"></div>
          </SubscribeLoyaltyCampaignCard>
          <div class="actions">
            <LoyaltyMdcButton v-if="!campaign.subscribed"
              big
              @click="activateStep('insert_id_tari')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator"></div>
            <LoyaltyMdcButton
              big
              @click="operationCompleted"
              >
              {{showRewardsString}}
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>

</swiper-js>
</template>

<script>
import LoginRequiredMixin from '@paiuolo/pai-vue-mixins/mixins/LoginRequiredMixin'
import xhrPostMixin from '@paiuolo/pai-vue-mixins/mixins/xhrPostMixin'
import ErrorResponseMixin from '@paiuolo/pai-vue-mixins/mixins/ErrorResponseMixin'

import SwiperJs from '@paiuolo/pai-vue-swiper/elements/swiper/SwiperJs'
import StepOperationSwiperJsMixin from '@paiuolo/pai-vue-swiper/mixins/StepOperationSwiperJsMixin'

import LoyaltyMdcTextField from '../../../../elements/input/loyalty/LoyaltyMdcTextField'
import LoyaltyMdcButton from '../../../../elements/button/loyalty/LoyaltyMdcButton'

import SubscribeLoyaltyCampaignCard from './SubscribeLoyaltyCampaignCard'
import UserCard from '../../../user/UserCard'


export default {
  name: 'SubscribeCampaign',
  mixins: [
    StepOperationSwiperJsMixin,
    LoginRequiredMixin,
    xhrPostMixin,
    ErrorResponseMixin
  ],
  components: {
    LoyaltyMdcTextField,
    LoyaltyMdcButton,
    SubscribeLoyaltyCampaignCard,
    UserCard,
    SwiperJs
  },
  props: {
    campaign: {
      type: Object,
      default: null
    },
    subscriber: {
      type: Object,
      default: null
    },
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.com/login/'
    },
    campaignsBaseUrl: {
      type: String,
      default: '/eco-fedelta/'
    }
  },
  data () {
    return {
      acceptString: this.gettext('Accept'),
      loginString: this.gettext('Login'),
      continueString: this.gettext('Continue'),
      showRewardsString: this.gettext('Show rewards'),
      tos2: 'L’utente, iscrivendosi al sistema tramite la piattaforma e cliccando su “Accetta”, dichiara di accettare integralmente il contenuto del documento "Condizioni di partecipazione e modalità di attribuzione dei punti Eco-Fedeltà" e l’Informativa ai sensi dell’art. 13 del Regolamento UE N. 2016/679.',
      campaignSubscriptionError: null,
      externalId: null,
      xhrPostOptions: {
        withCredentials: true
      },
      xhrPostPermittedErrors: '403,401',

      stepOperation: {
        key: 'subscribe_campaign_CBBO-2018',
        name: 'Subscribe campaign CBBO 2018',
        description: this.gettext('Subscribe campaign'),
        activeStep: null,
        steps:
          [{
            key: 'check_user',
            name: 'Checking user',
            description: this.gettext('Checking user..'),
            next: 'read_info',
            result: null,
            error: null
          },
          {
            key: 'read_info',
            name: 'Read informations',
            description: this.gettext('...'),
            next: 'accept_privacy',
            result: null,
            error: null
          },
          {
            key: 'accept_privacy',
            name: 'Accept privacy',
            description: this.gettext('...'),
            next: 'insert_id_tari',
            result: null,
            error: null
          },
          {
            key: 'insert_id_tari',
            name: 'Insert ID TARI',
            description: this.gettext('Insert your TARI ID (check the bill)'),
            next: 'subscribe',
            result: null,
            error: null
          },
          {
            key: 'subscribe',
            name: 'Subscribing',
            description: this.gettext('Subscribing..'),
            next: 'review_subscriprion',
            result: null,
            error: null
          },
          {
            key: 'review_subscriprion',
            name: 'Review subscription',
            description: this.gettext('Subscribed!'),
            next: null,
            result: null,
            error: null
          }]
      }
    }
  },
  computed: {
    campaignImageStyle () {
      let style = {}
      if (this.campaign) {
        if (this.campaign.flyer_image) {
          style['background-image'] = 'url(' + this.campaign.flyer_image + ')'
        }
      }
      return style
    },
    externalIdFieldName () {
      if (this.campaign) {
        return this.gettext('TARI ID')
      } else {
        return null
      }
    },
    campaignSubscriptionMessage () {
      if (this.campaign) {
        return this.campaign.campaign_subscribed_message
      } else{
        return null
      }
    }
  },
  mounted () {
    // console.log('SubscribeCampaign mounted', this.activeStep)

    this.$on('xhrPostResponse', () => {
      // console.log('subscribeCampaign response', response, response.data)
      this.activateStep('review_subscriprion')
    })
    this.$on('xhrPostError', (error) => {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        this.campaignSubscriptionError = this.campaign.campaign_subscription_denied_message
      } else {
        let parsedError = this.parseError(error)
        this.campaignSubscriptionError = parsedError
      }

      this.activateStep('review_subscriprion')
      // this.$forceUpdate()
    })
  },
  methods: {
    clear () {
      this.activateStep('check_user')
      this.campaignSubscriptionError = null
    },
    stepActivated (step) {
      if (step === 'subscribe') {
        // console.log('subscribing')
        this.subscribeCampaign()
      }
    },
    slideChanged (slide) {
      if (slide === 'insert_id_tari' && !this.campaignSubscriptionError) {
        this.$refs.externalIdInput.focus()
      }
    },
    operationCancelled () {
      this.$emit('operationCancelled')
    },
    operationCompleted () {
      // console.log('Operation completed')
      this.operationCompleted = true
      this.$emit('campaignSubscribed', this.campaign)
      document.location.href = '/eco-fedelta/' + this.campaign.slug + '/'
    },
    updateExternalId (ev) {
      // console.log('update tari id', ev)
      this.externalId = ev
    },
    gotExternalId () {
      // console.log('GOT TARI ID', ev)
      this.activateStep('subscribe')
    },
    subscribeCampaign () {
      this.xhrPostUrl = '/api/v1/loyalty/campaigns/' + this.campaign.id + '/subscribe/'
      this.xhrPostParams = {
        'external_id': this.externalId
      }
      this.performXhrPost()
    },
    monthName (d)  {
      let ml = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembere']
      return ml[d.getUTCMonth()]
    },
    getYearAndMonth (s) {
      if (s) {
        let dateObj = new Date(s)
        let month = this.monthName(dateObj)
        let year = dateObj.getUTCFullYear()
        return '' + month + ' ' + year
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../scss/loyalty.theme";

@import "@material/card/mdc-card";

.subscribe-loyalty-campaign {
  .step {
    background-color: #fff;
    /*padding-bottom: 32px;*/

    .description {
      font-size: 32px;
      line-height: 36px;
    }
  }

  .actions {
    @include flexbox();
    @include flex-direction(row);

    align-items: center;
    justify-content: center;

    height: 140px;
    box-sizing: border-box;
    padding: 0 36px;

    .separator {
      @include flex(1);
    }
    .privacy {
      max-width: 200px;
      max-height: 100%;
      overflow-y: auto;
      text-align: justify;
      padding-right: 16px;

      @media screen and (min-width: $break-phone) {
        max-width: 600px;
      }
    }

    @media screen and (max-width: $break-phone) {
      padding: 0 24px;
    }
  }

  .saldo_punti {
    padding: 0 24px;

    h2 {
      color: #ff4081;
      font-weight: normal;
    }

    h1 {
      font-weight: normal;
      line-height: 1.2em;
    }
    .score_assignment_score {
      font-weight: bold;
      font-size: 110%;
      color:  #555;
    }
  }
  .tos {
    margin: 24px 0 0;
  }
}
</style>
