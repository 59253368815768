<template>
  <div class="loyalty-campaign-list-item">

    <div class="entity-detail">
      <div class="logo" :style="logoStyle"></div>
      <div class="info flex">
        <div class="item-slogan" v-if="entity.slogan" v-html="entity.slogan"></div>
        <div class="item-slogan" v-else>
          <img v-if="entity.image" :src="entity.image" :alt="entity.name" />
          <h4 v-else>{{entity.name}}</h4>
        </div>
      </div>
    </div>

    <div class="tastiScelta">
      <LoyaltyEntityListButton
        ref="subscribeButton"
        @click="showDialog"
        >
        <big v-if="entity.subscribed">
          {{entity.score}}<br/>
          {{pointsText}}
        </big>
        <div v-else-if="entity.is_active">
          <i class="material-icons  mdc-button__icon" aria-hidden="true">person</i>
          {{subscribeText}}
        </div>
        <big v-else>
          Campagna terminata
        </big>
      </LoyaltyEntityListButton>

      <LoyaltyEntityListButton
        right
        ref="prizesButton"
        @click="showRewards"
        >
        <div>
          <i class="material-icons  mdc-button__icon" aria-hidden="true">
            card_giftcard
          </i>
          <span>{{rewardsText}}</span>
        </div>
      </LoyaltyEntityListButton>
    </div>

    <MdcDialog
      ref="dialog"
      borderless
      @closed="modalClosed"
      @opened="modalOpened">

      <SubscribeLoyaltyCampaign
        v-if="showOperation"
        ref="subscribeCampaign"
        :campaign="entity"
        :subscriber="subscriber"
        :login-url="loginUrl"
        @operationCancelled="hideDialog" />

    </MdcDialog>
  </div>
</template>

<script>
import EntityAsPropMixin from '@paiuolo/pai-vue-mixins/mixins/EntityAsPropMixin'
import EntityWithLogoMixin from '@paiuolo/pai-vue-mixins/mixins/EntityWithLogoMixin'
import LoginRequiredMixin from '@paiuolo/pai-vue-mixins/mixins/LoginRequiredMixin'

import SubscribeLoyaltyCampaign from './step-operations/SubscribeLoyaltyCampaign'
import MdcDialog from '../../../elements/dialog/MdcDialog'

import LoyaltyEntityListButton from '../../../elements/button/loyalty/LoyaltyEntityListButton'


export default {
  name: 'LoyaltyCampaignListItem',
  mixins: [
    EntityAsPropMixin,
    EntityWithLogoMixin,
    LoginRequiredMixin
  ],
  components: {
    LoyaltyEntityListButton,
    SubscribeLoyaltyCampaign,
    MdcDialog
  },
  props: {
    campaign: {
      type: Object,
      default: null
    },
    subscriber: {
      type: Object,
      default: null
    },
    campaignsBaseUrl: {
      type: String,
      default: '/eco-fedelta/'
    },
  },
  data () {
    return {
      pointsText: this.gettext('points'),
      subscribeText: this.gettext('Subscribe'),
      rewardsText: this.gettext('Rewards'),
      showOperation: false
    }
  },
  methods: {
    showDialog (ev) {
      if (this.entity.is_active) {
	this.$refs.dialog.open(ev)
      }
    },
    hideDialog () {
      this.$refs.dialog.close()
    },
    showRewards () {
      document.location.href = this.campaignsBaseUrl + this.entity.slug + '/'
    },
    modalOpened () {
      this.$nextTick(() => {
        this.showOperation = true
      })
    },
    modalClosed () {
      this.showOperation = false
    }
  }
}
</script>

<style lang="scss">
@import "../../../../scss/loyalty.theme";

.loyalty-campaign-list-item {
  @include loyalty-entity-list-item;
}
</style>
