<template>
  <div class="campaign-list">

    <LoadingBox
      v-if="loading"
      class="loading-box" />

    <LoyaltyCampaignListItem
      v-for="item in entities"
      :key="item.slug"
      :entity="item"
      :subscriber="userProfile"
      :login-url="loginUrl"
      :campaigns-base-url="campaignsBaseUrl"
      />
  </div>
</template>

<script>
import xhrGetMixin from '@paiuolo/pai-vue-mixins/mixins/xhrGetMixin'
import EntityListMixin from '@paiuolo/pai-vue-mixins/mixins/EntityListMixin'

import DjangoSsoAppUserElementMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/DjangoSsoAppUserElementMixin'

import LoyaltyCampaignListItem from './LoyaltyCampaignListItem'

import LoadingBox from '../../../elements/loader/LoadingBox'

export default {
  name: 'LoyaltyCampaignList',
  mixins: [
    DjangoSsoAppUserElementMixin,
    xhrGetMixin,
    EntityListMixin
  ],
  components: {
    LoadingBox,
    LoyaltyCampaignListItem
  },
  props: {
    auto: {
      type: Boolean,
      default: false
    },
    subscriber: {
      type: Object,
      default: null
    },
    campaigns: {
      type: Array,
      default: null
    },
    campaignsUrl: {
      type: String,
      default: 'https://game.q-cumber.com/api/v1/loyalty/campaigns/'
    },
    campaignsBaseUrl: {
      type: String,
      default: '/eco-fedelta/'
    },
    userProfileUrl: {
      type: String,
      default: 'https://game.q-cumber.com/api/v1/auth/profile/'
    },
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.com/login/'
    },
    hideOver: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      xhrGetUrl: this.campaignsUrl,
      xhrGetParams: {
        withCredentials: true
      },
      entities: this.campaigns
    }
  },
  mounted () {
    this.$on('performingXhrGet', () => {
      this.startLoading()
    })
    this.$on('performingFetchUserProfile', () => {
      this.startLoading()
    })
    this.$on('xhrGetResponse', (response) => {
      this.entities = response.data.results
      this.stopLoading()
    })
    this.$on('userIsAnonymous', () => {
      this.refreshCampaigns()
      this.stopLoading()
    })
    this.$on('userProfileFetched', () => {
      this.stopLoading()
    })

    if (this.subscriber === null) {
      if (this.auto) {
        this.fetchUserProfile()
      }
    } else {
      this.refreshCampaigns()
    }
  },
  methods: {
    refreshCampaigns () {
      if (this.campaigns) {
        this.entities = this.campaigns
      } else {
        this.performXhrGet()
      }
    }
  },
  watch: {
    campaigns (n) {
      this.entities = n
    },
    subscriber (n) {
      if (n) {
        this.userProfile == n
      }
    },
    userProfile (n) {
      if (n) {
        this.refreshCampaigns()
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../scss/loyalty.theme";

.campaign-list {
  position: relative;
  min-height: 170px;
}
</style>