<template>
  <div class="loyalty-entity-list-button"
    :class="{left: left, right: right}"
    ref="button"
    @click="$emit('click')"
    >

    <slot></slot>

  </div>
</template>

<script>
import { MDCRipple } from '@material/ripple'
// import { MDCRipple } from '@material/ripple/dist/mdc.ripple'  // built element


export default {
  name: 'LoyaltyEntityListButton',
  props: {
    left: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ripple: null
    }
  },
  mounted () {
    this.ripple = new MDCRipple(this.$refs.button)
  },
  methods: {
    subscribe (ev) {
      this.$refs.dialog.open(ev)
    },
    showRewards () {
      document.location.href = this.campaignsBaseUrl + this.entity.slug + '/'
    },
    closeModal () {
      this.$refs.dialog.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

@import "@material/ripple/mdc-ripple";

.loyalty-entity-list-button {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  color: #fff;

  @include loyalty-font;

  @include mdc-ripple-surface;
  @include mdc-ripple-radius-bounded;
  @include mdc-states-base-color(white);
  @include mdc-states-hover-opacity(.1);
  @include mdc-states-focus-opacity(.3);
  @include mdc-states-press-opacity(.4);
  @include mdc-states;

  width: 180px;
  height: 170px;
  border-radius: 0;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  @include flexbox();
  @include flex-direction(row);

  align-items: center;
  justify-content: center;

  i, span {
    color: #fff;
    display: block;
  }

  i {
    font-size: 26px;
    margin-bottom: 4px;
  }

  big {
    text-align: center;
    margin: 8px 16px;
    padding: 4px 8px;
    font-size: 22px;
    border: 2px solid #fff;
  }

  span {
    text-transform: uppercase;
  }

  @media screen and (max-width: $break-tablet) {
    width: auto;
    @include flex(1);
  }
}

.loyalty-entity-list-button.left {
  background-color: #83b99e;
}
.loyalty-entity-list-button.right {
  background-color: #73aeae;
}
</style>
 
